import { render, staticRenderFns } from "./school_home.vue?vue&type=template&id=1bf3b4ac&scoped=true"
import script from "./school_home.vue?vue&type=script&lang=js"
export * from "./school_home.vue?vue&type=script&lang=js"
import style0 from "./school_home.vue?vue&type=style&index=0&id=1bf3b4ac&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf3b4ac",
  null
  
)

export default component.exports